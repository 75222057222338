import { getAllActive as getActiveModules } from "../api/providers/modules";

let modules = null;
let modulesPromise = null;

export async function moduleIsActive(moduleName) {
    try {
        await ensureModulesLoaded();
        const module = modules.find(m => m.name === moduleName);

        return module !== undefined && (module.defaultIsActive || module.isActive);
    } catch (e) {
        return false;
    }
}

export const getModuleDisplayName = (moduleName) =>
    (modules ?? []).find(m => m.name === moduleName)?.displayName ?? "";

async function ensureModulesLoaded() {
    if (modules === null) {
        if (modulesPromise === null) {
            modulesPromise = getActiveModules().then(apiModules => {
                modules = apiModules;
                modulesPromise = null;
                return modules;
            });
        }
        await modulesPromise;
    }
}